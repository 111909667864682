import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MainComponent from './MainComponent';
import { Container, Row, Col } from "react-bootstrap";
import { InValidURL } from './InValidURL';

const Routes = () => {
  const [defaultParam, setDefaultParam] = useState('');
  const InvalidURLMsge="There is no data with mentioned url, please provide a valid one";

  return (
    <Router>
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to={`/${defaultParam}`} />} /> */}
        <Route exact path="/" render={() =>
          defaultParam === '' ? (
            <InValidURL msg={InvalidURLMsge}
              errLoading={true} />

          ) : (
            <Redirect to={`/${defaultParam}`} />
          )
        } />
        <Route path="/:param" component={MainComponent} />
      </Switch>
    </Router>
  );
};



export default Routes;
