import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

function StarRatingQuestion({ question, handleChange, formValues, showError }) {
    const hasAnswer = formValues[question.tempId];
    const [rating, setRating] = useState(null);
    const isMandatory = question.isMandatory;

    const handleRatingChange = (value) => {
        setRating(value);
        handleChange({
            target: { name: question.tempId, value },
        });
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= question.rateMax; i++) {
            stars.push(
                <Button
                    key={i}
                    variant="light"
                    className="mx-1"
                    onClick={() => handleRatingChange(i)}
                >
                    <i
                        className={`bi bi-star${rating >= i ? "-fill" : ""} text-warning`}
                    ></i>
                </Button>
            );
        }
        return stars;
    };

    return (
        <Card className={`my-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
            <Card.Body>
                <div className="d-flex align-items-center mb-3">
                    <div className="d-flex flex-column">

                        <Form.Label>
                            <b>
                                {question.questionText}
                                {isMandatory && <span className="text-danger">*</span>}
                            </b>
                        </Form.Label>
                        <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
                        {isMandatory && showError && !hasAnswer && (
                            <ErrorComponent message={question.requiredErrorText} />
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-center">{renderStars()}</div>
            </Card.Body>
        </Card>
    );
}

export default StarRatingQuestion;
