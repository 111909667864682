import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

function OpenTextQuestion({ question, handleChange,formValues, showError}) {
  const hasAnswer = formValues[question.tempId];
  const [editorValue, setEditorValue] = useState("");
  const [isAnswerValidOT, setIsAnswerValidOT] = useState(true);

  const handleEditorChange = (event) => {
    const value = event.target.value;
    if (value.length > 1024) {
      setIsAnswerValidOT(false); // Set validation status to false
      return;
    }
    setIsAnswerValidOT(true); // Set validation status to true
    setEditorValue(value);
    handleChange(event);
  };

  return (
    <Card className={`mt-3 ${question.isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">

            <Form.Label>
              <b>
                {question.questionText}
                {question.isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
            {question.isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />

            )}

            {!isAnswerValidOT && (
              <ErrorComponent message="Answer should contain max 1024 characters" />
            )}
          </div>
          <Form.Control
            as="textarea"
            rows={3}
            value={editorValue}
            name={question.tempId}
            onChange={handleEditorChange}
            required={question.isMandatory}
            placeholder={question.placeHolderText}
          />


        </Form.Group>
      </Card.Body>
    </Card>
  );
}


export default OpenTextQuestion;
