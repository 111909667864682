import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../Components/ErrorMessage";

const NumericQuestion = ({ question, handleChange, formValues, showError }) => {
  const { tempId, questionText,questionDescription, inputType, minValue, maxValue, isMandatory, requiredErrorText, placeHolderText } = question;
  const [isAnswerValidNumeric, setIsAnswerValidNumeric] = useState(true);
  const hasAnswer = formValues[question.tempId];

  const handleNumericChange = (event) => {
    const value = event.target.value;
    if (value < minValue || value > maxValue) {
      setIsAnswerValidNumeric(false); // Set validation status to false
      return;
    }
    setIsAnswerValidNumeric(true);
    handleChange(event);
  };

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">

            <Form.Label>
              <b>
                {questionText}
                {isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
              <b style={{ color: 'lightblue' }}>{questionDescription}</b>
            </Form.Label>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={requiredErrorText} />
            )}
            {!isAnswerValidNumeric && (
              <ErrorComponent message={`Age cannot be less than ${minValue} or more than ${maxValue}`} />
            )}
          </div>
          <Form.Control
            type="number"
            name={tempId}
            required={isMandatory}
            minValue={minValue}
            maxValue={maxValue}
            onChange={handleNumericChange}
            placeholder={placeHolderText}
          />

        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default NumericQuestion;
