import React from "react";

const ErrorComponent = ({ message}) => {
  return (
    <div style={styles.errorContainer}>
      <div style={styles.icon}>!</div>
      <div style={styles.message}>{message}</div>
    </div>
  );
};

const styles = {
  errorContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffe6e6",
    color: "#721c24",
    padding: "10px",
    borderRadius: "5px",
    marginBottom:"15px"
  },
  icon: {
    fontSize: "24px",
    fontWeight: "bold",
    marginRight: "10px",
  },
  message: {
    fontSize: "16px",
  },
};

export default ErrorComponent;

