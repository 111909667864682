import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

const SingleDropdownQuestion = ({ question, formValues, handleChange, showError }) => {
  const isMandatory = question.isMandatory;
  const hasAnswer = formValues[question.tempId];
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sortedChoices, setSortedChoices] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let choicesToRender = question.choices;
    if (question.choicesOrder === "random") {
      choicesToRender = shuffleArray(question.choices);
    } else if (question.choicesOrder === "ascending") {
      choicesToRender = question.choices.sort((a, b) => a.text.localeCompare(b.text));
    } else if (question.choicesOrder === "descending") {
      choicesToRender = question.choices.sort((a, b) => b.text.localeCompare(a.text));
    }
    setSortedChoices(choicesToRender);
  }, [question]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const optionsLength = question.choices.reduce((max, choice) => {
    return choice.text.length > max ? choice.text.length : max;
  }, 0);


  const selectWidth = optionsLength < 20 ? (isMobile ? "80%" : "20%") : "80%";
  const optionWidth = optionsLength < 20 || isMobile ? (isMobile ? "80%" : "20%") : "80%";



  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex flex-column">

            <b>
              <label htmlFor={question.tempId}>
                {question.questionText}{" "}
                {isMandatory && <span className="text-danger">*</span>}
              </label>
            </b>

            <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>
          <select
            id={question.tempId}
            name={question.tempId}
            value={formValues[question.tempId] || ""}
            onChange={handleChange}
            className="form-select"
            style={{ width: selectWidth, marginTop: '5px', padding: '10px' }}
          >
            <option value="">Select an option</option>
            {sortedChoices.map((choice, index) => (
              <option key={index} value={choice.value} style={{ width: optionWidth }}>
                {choice.text}
              </option>
            ))}
          </select>

        </div>
      </Card.Body>
    </Card>
  );
};

export default SingleDropdownQuestion;
