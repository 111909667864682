import React from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

const MobileMatrixQuestion = ({ question, formValues, handleChange, showError }) => {
  // const hasAnswer = question.rows.some((row, index) => {
  //   const key = `${question.tempId}_r${index + 1}`;
  //   return formValues[key] !== undefined && formValues[key] !== "";
  // });
  const hasAnswer =
    formValues[question.tempId] &&
    formValues[question.tempId].length > 0 &&
    formValues[question.tempId].some((answer) => answer.value !== "");



  return (
    <Card className={`mt-3 ${question.isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">
            <Form.Label>
              <b>
                {question.questionText}
                {question.isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
            {question.isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>
          {question.rows.map((row, rowIndex) => (
            <div key={rowIndex} className="col-12 col-md-6">
              <div className="border p-3 mb-3">
                <div className="row mb-3" key={rowIndex}>
                  <div className="col-12">
                    <Form.Label>
                      <b>{row}</b>
                    </Form.Label>
                  </div>
                  {question.columns.map((column, colIndex) => (
                    <div className="col-12 col-md-4" key={colIndex}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`${question.tempId}_r${rowIndex + 1}_${colIndex}`}
                          name={`${question.tempId}_r${rowIndex + 1}`}
                          value={`${column}`}
                          checked={
                            formValues[question.tempId] &&
                            formValues[question.tempId].findIndex((item) => {
                              return Object.keys(item)[0] === `${question.tempId}_r${rowIndex + 1}` &&
                                item[`${question.tempId}_r${rowIndex + 1}`] === column;
                            }) !== -1
                          }
                          onChange={handleChange}
                          required={question.isMandatory}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${question.tempId}_r${rowIndex + 1}_${colIndex}`}
                        >
                          {column}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default MobileMatrixQuestion;
