import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

export const InValidURL = ({msg,errLoading}) => {
  return (
    <Container>
          <Row className="justify-content-center">
            <Col xs={6}>
              {errLoading && <p className="text-center">
              <div style={{'fontSize':'23px'}} dangerouslySetInnerHTML={{ __html: msg }} />
              {/* {msg} */}
              </p>
              } </Col>
          </Row>
        </Container>
  )
}
