import React, { useState } from "react";
import jsep from "jsep";
import NumericQuestion from "../Questions/NumericQuestion";
import { Container, Row, Col, Image } from "react-bootstrap";
import MultiselectDropdown from "../Questions/MultiSelectionDropDown/MultiSelectionDropdown";
import ImagePicker from "../Questions/ImageSelection/Imageselection";
import DateQuestion from "../Questions/DateSelection/DateSelection";
import SliderScaleQuestion from "../Questions/SliderScale/SliderScale";
import NPSQuestion from "../Questions/NPS/NPSSelection";
import StarRatingQuestion from "../Questions/Stars/StarQuestion";
import SelectListQuestion from "../Questions/SelectList/SelectList";
import BooleanQuestion from "../Questions/Boolean/BooleanQuestion";
import RadioQuestion from "../Questions/Radio/RadioQuestion";
import MultiChoiceQuestion from "../Questions/MultiChoice/MultiChoiceQuestion";
import RatingQuestion from "../Questions/Rating/RatingQuestion";
import MatrixQuestion from "../Questions/SingleMatrix/SingleMatrixQuestion";
import MatrixMultiChoiceQuestion from "../Questions/MultiMatrix/MultiMatrixQuestion";
import SingleDropdownQuestion from "../Questions/SingleDropDown/SingleDropDown";
import HeadingQuestion from "../Questions/Heading/HeadingQuestion";
import OpenTextQuestion from "../Questions/OpenText/OpenText";
import RankingQuestionDND from "../Questions/RankingDND/DragAndDropQuestion";
import Footer from "../../Footer/Footer";
import SelectListQuestionWithArr from "../Questions/SelectList/NewSelectList";
import { API_END_POINT } from "../../config";

const Questionnaire = ({ pages, Latitude, Longitude, wholeWord }) => {
    const [formValues, setFormValues] = useState({});
    const [selectedCell, setSelectedCell] = useState({});
    const [pageIndex, setPageIndex] = useState(0);
    const [showError, setShowError] = useState(false);
    const [isAnswerValid, setIsAnswerValid] = useState(true);
    const [responseID, setResponseID] = useState(0);

    const currentPage = pages.pages[pageIndex];

    const handleChange = (e) => {
        const { name, value, type, checked, otherText } = e.target;
        const normalizedTempId = name;
        switch (type) {
            case "checkbox":
                setFormValues((prevState) => ({
                    ...prevState,
                    [normalizedTempId]: checked
                        ? [...(prevState[normalizedTempId] || []), value]
                        : (prevState[normalizedTempId] || []).filter((val) => val !== value),
                }));
                break;
            case "matrix":
                if (normalizedTempId.includes("_")) {
                    const [row, col] = normalizedTempId.split("_").slice(1);
                    setSelectedCell({ row: parseInt(row), col: parseInt(col) });
                    setFormValues((prevState) => ({
                        ...prevState,
                        [`${normalizedTempId.split("_")[0]}_${row}`]: value, // Use row as the key
                    }));
                }
                break;
            case "matrixmultiChoice":
                if (normalizedTempId.includes("_")) {
                    const [row, col] = normalizedTempId.split("_").slice(1);
                    setSelectedCell({ row: parseInt(row), col: parseInt(col) });
                    setFormValues((prevState) => ({
                        ...prevState,
                        [`${normalizedTempId}_row${row}`]: [
                            ...(prevState[`${normalizedTempId}_row${row}`] || []),
                            value,
                        ],
                    }));
                }
                break;
            case "radio":
                setFormValues((prevState) => ({ ...prevState, [normalizedTempId]: [value] }));
                break;
            default:
                setFormValues((prevState) => ({ ...prevState, [normalizedTempId]: value }));
                break;
        }
    };

    const handleSwitchChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleDropDownChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formValues); // do something with the form values
    };

    const evaluateExpression = (expression) => {
        try {
            expression = expression
                .replaceAll("and", "&&")
                .replaceAll("or", "||")
                .replaceAll(/([^><=!])=([^=])/g, "$1==$2");

            const replacedExpression = expression.replace(/{(.*?)}/g, (match, p1) => {
                const value = formValues[p1.trim()];
                if (Array.isArray(value)) {
                    const containsAnyElement = value.some((element) => new RegExp(`\\b${element}\\b`).test(expression));
                    return containsAnyElement;
                } else if (!isNaN(value)) {
                    return parseInt(value);
                } else {
                    return `'${value}'`;
                }
            });

            const leftSide = replacedExpression.split(" ")[0].replace("(", "");

            if (leftSide.startsWith("true")) {
                return true;
            }
            const ast = jsep(replacedExpression);

            const variables = ast.variables ? Object.keys(ast.variables) : [];
            const values = variables.map((variable) => formValues[variable]);
            const fn = new Function(...variables, `return ${replacedExpression};`);
            const result = fn(...values);
            // console.log(`Expression "${expression}" evaluated to ${result}`);
            return result;
        } catch (error) {
            console.error(error, "error");
            return false;
        }
    };



    const handleNPSChange = (tempId, value) => {
        const updatedValues = {
            ...formValues,
            [tempId]: value
        };
        setFormValues(updatedValues);
    };

    const handleStarChange = (event) => {
        const { name, value } = event.target;
        const updatedValues = {
            ...formValues,
            [name]: value
        };
        setFormValues(updatedValues);
    };

    const validateAnswer = (question) => {
        const answer = formValues[question.tempId];
        return answer && answer.trim().length > 0;
    };
    const renderQuestion = (question) => {
        let inputElement;

        switch (question.type) {
            case "heading":

                inputElement = (
                    <HeadingQuestion
                        questionText={question?.questionText}
                    />
                );
                break;

            case "numeric":

                inputElement = (
                    <NumericQuestion
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError}
                    />
                );
                break;
            case "ranking":

                inputElement = (
                    <RankingQuestionDND
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError}
                    />
                );
                break;

            case "openText":

                inputElement = (
                    <OpenTextQuestion
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError}
                    />
                );
                break;


            case "radiogroup":
                inputElement = (
                    <RadioQuestion
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError} />
                );
                break;

            case "checkbox":
                inputElement = (
                    <MultiChoiceQuestion
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError}

                    />
                );
                break;

            case "rating":
                inputElement = (
                    <RatingQuestion
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError}
                    />
                );
                break;
            case "matrix":
                inputElement = (
                    <MatrixQuestion question={question}
                        formValues={formValues}
                        handleChange={handleChange}
                        showError={showError} />

                );
                break;

            case "matrixmultiChoice":
                inputElement = (
                    <MatrixMultiChoiceQuestion
                        question={question}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        showError={showError} />
                );
                break;
            case "singleDropdown":
                inputElement = (
                    <SingleDropdownQuestion
                        question={question}
                        formValues={formValues}
                        handleChange={handleChange}
                        showError={showError} />
                );
                break;

            case "imagepicker":
                inputElement = (
                    <ImagePicker
                        key={question.tempId}
                        question={question}
                        formValues={formValues}
                        handleChange={handleDropDownChange}
                        showError={showError}
                    />
                );
                break;

            case "date":
                inputElement = (
                    <DateQuestion
                        question={question}
                        handleChange={handleDropDownChange}
                        formValues={formValues}
                        showError={showError} />
                );
                break;

            case "sliderScale":
                inputElement = (
                    <SliderScaleQuestion
                        question={question}
                        handleChange={handleDropDownChange}
                        formValues={formValues}
                        showError={showError}
                    />

                );
                break;

            case "NPS":
                inputElement = (
                    <NPSQuestion
                        question={question}
                        handleChange={handleNPSChange}
                        formValues={formValues}
                        showError={showError} />

                );
                break;
            case "starRating":
                inputElement = (
                    <StarRatingQuestion
                        question={question}
                        handleChange={handleStarChange}
                        formValues={formValues}
                        showError={showError} />

                );
                break;

            case "selectList":
                inputElement = (
                    <SelectListQuestionWithArr
                        question={question}
                        handleChange={handleChange}
                        formValues={formValues}
                        showError={showError} />

                );
                break;

            case "multiselectdropdown":
                inputElement = (
                    <MultiselectDropdown
                        key={question.tempId}
                        question={question}
                        formValues={formValues}
                        showError={showError}
                        setFormValues={setFormValues}
                        handleChange={handleDropDownChange}
                    />
                );
                break;


            case "boolean":
                inputElement = (
                    <BooleanQuestion
                        key={question.tempId}
                        question={question}
                        handleChange={handleSwitchChange}
                        formValues={formValues}
                        showError={showError}
                    />
                );
                break;

            default:
                inputElement = null;
        }

        const visible =
            !question.visibleIf || evaluateExpression(question.visibleIf);

        return (
            <div className="container-fluid h-100" key={question.tempId} style={{ display: visible ? "block" : "none" }}>
                {/* <label>{question.questionText}</label> */}
                {inputElement}
            </div>
        );
    };
    // Recursive function to flatten nested arrays
    function flattenNestedArrays(arr) {
        return arr.reduce((result, item) => {
            if (Array.isArray(item)) {
                result.push(...flattenNestedArrays(item));
            } else {
                result.push(item);
            }
            return result;
        }, []);
    }

    const renderPage = (page, pageIndex) => {
        const visible = !page.visibleIf || evaluateExpression(page.visibleIf);

        const onNextClick = async (e) => {
            e.preventDefault();

            // validate form
            const currentPageElements = pages.pages[pageIndex].elements;
            let isValid = true;
            currentPageElements.forEach((element) => {
                if (element.visibleIf === undefined || element.visibleIf === "" || evaluateExpression(element.visibleIf)) {
                    if (element.isMandatory && !formValues[element.tempId]) {
                        isValid = false;
                    }
                }
            });



            if (isValid && isAnswerValid) {
                setShowError(false);

                // check if there are any visibleIf conditions that are satisfied
                for (let i = pageIndex + 1; i < pages.pages.length; i++) {
                    const page = pages.pages[i];
                    const visibleElement = page.elements.find((element) => {
                        const visibleIf = element.visibleIf;
                        return !visibleIf || evaluateExpression(visibleIf);
                    });

                    let postObject = {};

                    let now = new Date();
                    let responseDate = ExtractDateAndTime(now, false);
                    const respondentCode = wholeWord;
                    const surveyLocation = Latitude + ',' + Longitude;
                    const surveyId = 0;
                    const surveyCode = wholeWord;

                    // Create a new array of objects
                    const newArray = Object.keys(formValues).map((tempId) => {
                        let answer = formValues[tempId];
                        let id = null;

                        // Find the matching object in the array based on tempID
                        const matchingObject = currentPage.elements.find((obj) => obj.tempId === tempId);

                        // If a matching object is found, assign the id property to the id variable
                        if (matchingObject) {
                            id = matchingObject.id;
                        }

                        // Convert array values to comma-separated strings
                        if (Array.isArray(answer)) {
                            answer = answer.map((item) => {
                                if (typeof item === "object") {
                                    return JSON.stringify(item);
                                }
                                return item;
                            }).join(", ");
                        }

                        return {
                            id: id, // Assign the retrieved id to the id property
                            code: tempId,
                            answer: answer,
                        };
                    });

                    const updatedQuestions = newArray.filter(x => x.id != null);
                    const surveyResponse = {
                        responseid: pageIndex == 0 ? 0 : responseID,
                        responsedate: responseDate,
                        respondentcode: respondentCode,
                        surveylocation: surveyLocation,
                        answer: {
                            surveyid: surveyId,
                            surveycode: surveyCode,
                            questions: updatedQuestions
                        }
                    };

                    console.log(surveyResponse, "surveyResponse");

                    if (pageIndex == 0) {
                        await fetch(`${API_END_POINT.BASE_URL}/api/Response/PostResponse`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            // body: JSON.stringify({ data: 'some data' })
                            body: JSON.stringify(surveyResponse)
                        })
                            .then(response => response.json())
                            .then(data => {
                                // setResponse(data);
                                setResponseID(data.data.Status);
                                console.log(data.data, "return Response");

                            });
                    }
                    else {
                        // Trigger the API call in the background
                        const apiPromise = fetch(`${API_END_POINT.BASE_URL}/api/Response/PostResponse`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(surveyResponse)
                        }).then(response => response.json());

                        // Updating the UI with the new data when the promise resolves
                        apiPromise.then(data => {
                            // Updating the state with the new data
                            setResponseID(data.data.Status);
                            console.log(data.data, "return Response");
                        });

                        // Navigate to the next question immediately

                    }
                    console.log(formValues, "answers")

                    if (visibleElement) {
                        // navigate to the page that contains the visible question
                        setPageIndex(i);
                        return;
                    }
                }

                // no visible questions, navigate to next page
                setPageIndex(pageIndex + 1);


            } else {
                setShowError(true);
            }
        };




        const onCompleteClick = (e) => {
            e.preventDefault();
            console.log(formValues); // do something with the form values
        };


        const titleStyle = {
            textAlign: pages.header.titlePosition,
        };

        const logoStyle = {
            float: pages.header.logoPosition,
            marginRight: pages.header.logoPosition === "right" ? "0px" : "10px",
            marginLeft: pages.header.logoPosition === "left" ? "0px" : "10px",
        };
        return (
            <div>
                <div
                // className="container-fluid h-100" 
                >
                    <div style={pages.header.surveyheadingBackground}>
                        <img src={pages.header.logo} style={{ ...logoStyle, width: '40px', height: '40px' }} />
                        <p style={{ ...pages.header.surveyheadingForeground, ...titleStyle }}>
                            {pages.header.title}
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} style={{ marginBottom: '50px' }}>
                        {currentPage.elements.map(renderQuestion)}
                        {/* {pageIndex < pages.pages.length - 1 ? (
                            <button type="button" onClick={onNextClick}>
                                Next
                            </button>
                        ) : (
                            <button type="submit" onClick={onCompleteClick}>
                                Complete
                            </button>
                        )} */}

                    </form>
                </div>
                <Footer
                    currentQuestion={currentPage}
                    currentQuestionIndex={pageIndex}
                    survey={pages}
                    handleNextClick={onNextClick}
                    handleBackClick={onNextClick}
                    handleSubmitClick={onCompleteClick}
                />
            </div>
        );
    };

    return <div>{pages.pages[pageIndex].elements && renderPage(pages.pages[pageIndex], pageIndex)}</div>;

    // return <div>{pages.pages.map(renderPage)}</div>;
};

export default Questionnaire;
function ExtractDateAndTime(now, timeRequired) {
    let year = now.getFullYear();
    let month = now.getMonth();
    let day = now.getDate();

    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();
    let currentStartDate = timeRequired == true ?
        `${year}-${month + 1}-${day} ${hours}:${minutes}:${seconds}`
        : `${year}-${month + 1}-${day}`;
    return currentStartDate;
}