import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import MobileMultiMatrixQuestion from "./MobileMatrixMulti";

const MatrixMultiChoiceQuestionCard = ({ question, formValues, setFormValues, showError }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  //const hasAnswer = Object.keys(formValues).some((key) => key.startsWith(`${question.tempId}_r`) && formValues[key].some((value) => value !== ""));
  const hasAnswer = formValues[question.tempId]?.some((answer) => {
    return Object.values(answer).some((rowValues) => {
      return rowValues.some((value) => value !== "");
    });
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // working code as old
  // const handleCheckboxChange = (e, rowIndex, colIndex) => {
  //   const isChecked = e.target.checked;
  //   const value = e.target.value;
  //   const key = `${question.tempId}_r${rowIndex + 1}`;
  //   const prevValues = formValues[key] || [];
  //   let questionColumns = [...question.columns];
  //   let newValues;
  //   if (isChecked) {
  //     newValues = [...prevValues, value];
  //   } else {
  //     newValues = prevValues.filter((v) => v !== value);
  //   }
  //   let finalValues = questionColumns.map(option => newValues.includes(option) ? option : '');
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     [key]: finalValues,
  //   }));
  // };




  const handleCheckboxChange = (e, rowIndex, colIndex) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    const key = `${question.tempId}_r${rowIndex + 1}`;
    const prevAnswers = formValues[question.tempId] || [];
    const prevAnswer = prevAnswers.find((a) => a[key]) || {};
    const prevValue = prevAnswer[key] || [];
    const newValues = isChecked ? [...prevValue, value] : prevValue.filter((v) => v !== value);
    let updatedColValues = question.columns.map(option => newValues.includes(option) ? option : '');
    const newAnswer = { [key]: updatedColValues };
    const newAnswers = prevAnswers.filter((a) => !a[key]).concat(newAnswer);
    setFormValues((prevState) => ({
      ...prevState,
      [question.tempId]: newAnswers,
    }));
  };

  if (isMobile) {
    return (
      <MobileMultiMatrixQuestion
        question={question}
        formValues={formValues}
        handleCheckboxChange={handleCheckboxChange}
        showError={showError}
      />
    );
  }

  return (
    <Card className={`mt-3 ${question.isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form>
          <Form.Group>
            <div className="d-flex flex-column">

              <Form.Label>
                <b>
                  {question.questionText}
                  {question.isMandatory && <span className="text-danger">*</span>}
                </b>
              </Form.Label>
              <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
              {question.isMandatory && showError && !hasAnswer && (
                <ErrorComponent message={question.requiredErrorText} />
              )}
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    {question.columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {question.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{row}</td>
                      {question.columns.map((column, colIndex) => (
                        <td key={colIndex}>
                          <Form.Check
                            type="checkbox"
                            id={`${question.tempId}_${rowIndex}_${colIndex}`}
                            name={`${question.tempId}_${rowIndex}_${colIndex}`}
                            value={column}
                            // label={column}
                            checked={formValues[`${question.tempId}_r${rowIndex + 1}`]?.includes(column)}
                            onChange={(e) => handleCheckboxChange(e, rowIndex, colIndex)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Form.Group>


        </Form>
      </Card.Body>
    </Card>
  );
};

export default MatrixMultiChoiceQuestionCard;

