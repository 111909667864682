import React, { useState } from 'react';
import { Card, Form, Badge } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './RankingQuestionDND.css';
import ErrorComponent from '../../../Components/ErrorMessage';

function RankingQuestionDND({ question, handleChange, formValues, showError }) {
  const hasAnswer = formValues[question.tempId];
  const [items, setItems] = useState(question.choices[0].split(','));
  const [isAnswerValidDND, setIsAnswerValidDND] = useState(true);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const itemsCopy = Array.from(items);
    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, reorderedItem);
    setItems(itemsCopy);
    setIsAnswerValidDND(true);
    handleChange({ target: { name: question.tempId, value: itemsCopy } });
  };

  const isMandatory = question.isMandatory;

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? 'border-danger' : ''}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">
            <Form.Label>
              <b>
                {question.questionText}
                {isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
              <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
            </Form.Label>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="items">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="ranking-item d-flex align-items-center"
                        >
                          <div className="dots-container">
                            <span className="dots">&#8942;</span>
                            <span className="dots">&#8942;</span>
                            <span className="dots">&#8942;</span>
                          </div>
                          <div className="badge-box">
                            <Badge bg="secondary">{index + 1}</Badge>
                          </div>
                          <div className="item-container">{item}</div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default RankingQuestionDND;
