import { useState, useEffect } from "react";
import { Form, Dropdown, Card, Badge } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

const MultiselectDropdown = ({ question, formValues, showError, setFormValues, handleChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedChoices, setSelectedChoices] = useState([]);
  const isMandatory = question.isMandatory;
  const hasAnswer = formValues[question.tempId];

  useEffect(() => {
    if (formValues[question.tempId]) {
      setSelectedChoices([...formValues[question.tempId]]);
    } else {
      setSelectedChoices([]);
    }
  }, [formValues, question]);

  const handleSelectOption = (event) => {
    const selectedValue = event.target.value;
    const selectedArray = [...selectedChoices];
    if (selectedArray.includes(selectedValue)) {
      selectedArray.splice(selectedArray.indexOf(selectedValue), 1);
    } else {
      selectedArray.push(selectedValue);
    }
    setSelectedChoices(selectedArray);
    setFormValues({ ...formValues, [question.tempId]: selectedArray });
  };

  const handleRemoveOption = (selected) => {
    const selectedArray = [...selectedChoices];
    selectedArray.splice(selectedArray.indexOf(selected), 1);
    setSelectedChoices(selectedArray);
    setFormValues({ ...formValues, [question.tempId]: selectedArray });
  };

  const handleToggleDropdown = () => {
    setSearchTerm("");
    setShowDropdown(!showDropdown);
  }

  const selectedChoiceValues = new Set(selectedChoices);
  const sortedChoices = question.choices
    .slice()
    .sort((a, b) => a.text.localeCompare(b.text))
    .reduce(
      (acc, choice) => {
        if (selectedChoiceValues.has(choice.value)) {
          acc.selected.push(choice);
        } else {
          acc.unselected.push(choice);
        }
        return acc;
      },
      { selected: [], unselected: [] }
    );

  const allChoices = sortedChoices.selected.concat(sortedChoices.unselected);


  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const maxWebChoices = isMobile ? 0 : 3;
  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <div className="d-flex flex-column">
          <Form.Label>
            <b>
              {question.questionText}
              {isMandatory && <span className="text-danger">*</span>}
            </b>
          </Form.Label>
          <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
          {isMandatory && showError && !hasAnswer && (
            <ErrorComponent message={question.requiredErrorText} />
          )}
        </div>
        <Dropdown style={{ width: '80%' }}
          show={showDropdown} onToggle={handleToggleDropdown}>
          <Dropdown.Toggle
            variant="secondary"
            className="w-100"
            id={`${question.tempId}-dropdown`}
            style={{
              backgroundColor: "white",
              color: "black",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
          >
            {selectedChoices.length === 0 && (
              <span>Select an option</span>
            )}
            {selectedChoices.length > 0 && selectedChoices.length <= maxWebChoices && (
              <span>
                {selectedChoices.map((selected) => (
                  <Badge
                    key={selected}
                    variant="secondary"
                    className="mr-1 mb-1"
                    onClick={() => handleRemoveOption(selected)}
                  >
                    {selected} <i className="fas fa-times-circle ml-1"></i>
                  </Badge>
                ))}
              </span>
            )}
            {selectedChoices.length > maxWebChoices && (
              <span>
                {isMobile ? (
                  `${selectedChoices.length} items selected`
                ) : (
                  <span>
                    {selectedChoices
                      .slice(0, maxWebChoices)
                      .map((selected) => (
                        <Badge
                          key={selected}
                          variant="secondary"
                          className="mr-1 mb-1"
                          onClick={() => handleRemoveOption(selected)}
                        >
                          {selected} <i className="fas fa-times-circle ml-1"></i>
                        </Badge>
                      ))}
                    {` +${selectedChoices.length - maxWebChoices} more`}
                  </span>
                )}
              </span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="w-100"
            style={{
              minWidth: "200px",
              maxHeight: "300px",
              overflowY: "auto",
              padding: "0.5rem",
            }}
          >
            <style>
              {`
              .dropdown-menu .form-check-label {
                cursor: pointer;
              }
              .dropdown-menu .form-check-label:hover {
                background-color: #f6f6f6;
              }
            `}
            </style>
            <div className="mb-2 d-flex align-items-center">
              <input
                type="text"
                placeholder="Search..."
                className="form-control flex-grow-1"
                value={searchTerm}
                onInput={(event) => setSearchTerm(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Escape") {
                    setSearchTerm("");
                  }
                }}
              />
              {searchTerm && (
                <button
                  type="button"
                  className="btn btn-outline-danger ml-2"
                  onClick={() => setSearchTerm("")}
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
            </div>


            {allChoices
              .filter((choice) =>
                choice.text.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((choice) => (
                <div
                  key={choice.value}
                  className="d-flex align-items-center justify-content-between w-100"
                >
                  <label htmlFor={`checkbox-${choice.value}`} className="w-100">
                    <Form.Check
                      type="checkbox"
                      label={choice.text}
                      value={choice.value}
                      id={`checkbox-${choice.value}`}
                      onChange={handleSelectOption}
                      checked={selectedChoices.includes(choice.value)}
                    />
                  </label>
                </div>
              ))}
          </Dropdown.Menu>
        </Dropdown>
        {/* {selectedChoices.length >= 8 &&
          <div className="mt-2">
            {selectedChoices.length > 0 &&
              <Badge variant="secondary" className="mr-1 mb-1">
                {selectedChoices.length} Selected <i className="fas fa-times-circle ml-1"></i>
              </Badge>
            }
          </div>
        } */}
      </Card.Body>
    </Card>

  );

}

export default MultiselectDropdown;

