import React, { useState, useEffect, useRef } from 'react';
import { Card, Form } from 'react-bootstrap';
import ErrorComponent from '../../../Components/ErrorMessage';

function SelectListQuestionWithArr({ question, handleChange, formValues, showError }) {
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const hasAnswer = formValues[question.tempId];
  const isMandatory = question.isMandatory;

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 3) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleSelectChange = (value) => {
    setSearchValue(value);
    handleChange({
      target: { name: question.tempId, value, type: question.type },
    });
    setShowDropdown(false);
  };

  const renderOptions = () => {
    const filteredOptions = question.choices[0].split(',').filter((option) =>
      option.toLowerCase().includes(searchValue.toLowerCase())
    );
    return filteredOptions.map((option) => (
      <div
        key={option}
        className="dropdown-item"
        onClick={() => handleSelectChange(option)}
      >
        {option}
      </div>
    ));
  };

  const handleWindowClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleEscapeKey = (e) => {
    if (e.key === 'Escape') {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? 'border-danger' : ''}`}>
      <Card.Body>
        <div className="d-flex flex-column">
          <Form.Label>
            <b>
              {question.questionText}
              {isMandatory && <span className="text-danger">*</span>}
            </b>
          </Form.Label>
          <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
          {isMandatory && showError && !hasAnswer && (
            <ErrorComponent message={question.requiredErrorText} />
          )}
        </div>
        <div className="dropdown" ref={dropdownRef}>
          <input
            type="text"
            name={question.tempId}
            value={searchValue}
            onChange={handleSearchChange}
            className="form-control"
            placeholder={question.placeHolderText}
          />
          {showDropdown && (
            <div className="dropdown-menu w-100" style={{ display: 'block' }}>
              {renderOptions()}
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default SelectListQuestionWithArr;
