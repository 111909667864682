import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";

const Footer = ({
    currentQuestion,
    currentQuestionIndex,
    survey,
    handleNextClick,
    handleBackClick,
    handleSubmitClick,
}) => {
    const [widthSize, setWidth] = useState(0);
    const [showBackButton, setShowBackButton] = useState(false);

    useEffect(() => {
        setShowBackButton(currentQuestionIndex > 0);
        // caluclate the questions length for progess bar
        setWidth(() => {
            let val = Math.floor((currentQuestionIndex + 1) / survey.pages.length * 100);
            return val;
        });
    }, [currentQuestionIndex]);

    return (
        <div className="d-flex justify-content-between" style={{
            "position": 'fixed', "bottom": "0",
            "justify-content": "center",
            "align-items": "center",
            "height": "50px",
            "width": "100%",
            "backgroundColor": survey.footer.footerBackgroundcolor,
        }}>
            {/* <Button variant="primary" style={{
                marginLeft: '15px',
                visibility: !showBackButton ? "hidden" : "visible"
            }} onClick={handleBackClick}>
                &lt;
            </Button> */}
            <Button variant="primary" style={{
                ...survey.footer.previousbuttonstyle,
                marginLeft: '15px',
                visibility: "hidden"
            }} onClick={handleBackClick}>
                {/* &lt; */}
                {survey.footer.previousbuttonlabel}

            </Button>
            {/* types of variants for progressBar :
           primary, secondary, success, info, warning, danger, dark, light */}
            {survey.footer.progressbar ?
                <ProgressBar now={widthSize}
                    label={`${widthSize}%`}
                    style={{
                        ...survey.footer.progressbarstyle,
                        width: "60%", marginLeft: "15px"
                    }}
                // variant="success"
                />
                : null
            }
            {/* <ProgressBar now={widthSize}
                label={`${widthSize}%`}
                style={{
                    ...survey.footer.progressbarstyle,
                    width: "60%", marginLeft: "15px"
                }}
            // variant="success"
            /> */}
            {/* {
                currentQuestion &&
                    currentQuestionIndex !== survey.survey.questions.length - 1 ? (
                    <Button variant="primary" onClick={handleNextClick} style={{ marginRight: '15px' }}>
                        &gt;
                    </Button>
                ) : (
                    <Button variant="primary" onClick={handleSubmitClick} style={{ marginRight: '15px' }} >
                        Submit
                    </Button>
                )
            } */}
            {
                currentQuestion &&
                (
                    <Button variant="primary" onClick={handleNextClick}
                        style={{
                            ...survey.footer.nextbuttonstyle
                            , marginRight: '15px'
                        }}
                    >
                        {/* &gt; */}
                        {survey.footer.nextbuttonlabel}
                    </Button>
                )
            }
        </div >
    );
};

export default Footer;
