import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import MobileMatrixQuestion from "./MobileSingleMatrix";

const MatrixQuestion = ({ question, formValues, handleChange, showError }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  // const hasAnswer = question.rows.some((row, index) => {
  //   const key = `${question.tempId}_r${index + 1}`;
  //   return formValues[key] !== undefined && formValues[key] !== "";
  // });

  const hasAnswer =
    formValues[question.tempId] &&
    formValues[question.tempId].length > 0 &&
    formValues[question.tempId].some((answer) => answer.value !== "");


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMatrixChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const row = key.substring(key.indexOf("_r") + 2);

    const newAnswers = formValues[question.tempId] || [];

    const newRow = {
      [`${question.tempId}_r${row}`]: value,
    };

    const index = newAnswers.findIndex((answer) =>
      Object.keys(answer)[0].includes(`_r${row}`)
    );

    if (index >= 0) {
      newAnswers[index][`${question.tempId}_r${row}`] = value;
    } else {
      newAnswers.push(newRow);
    }

    handleChange({
      target: {
        name: question.tempId,
        value: newAnswers,
      },
    });
  };

  if (isMobile) {
    return (
      <MobileMatrixQuestion
        question={question}
        formValues={formValues}
        handleChange={handleMatrixChange}
        showError={showError}
      />
    );
  }

  return (
    <Card className={`mt-3 ${question.isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">

            <Form.Label>
              <b>
                {question.questionText}
                {question.isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
              <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
            </Form.Label>
            {question.isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  {question.columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {question.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <th>{row}</th>
                    {question.columns.map((column, colIndex) => {
                      const answer = formValues[question.tempId]?.find(
                        (a) => Object.keys(a)[0] === `${question.tempId}_r${rowIndex + 1}`
                      );
                      const checked = answer?.[`${question.tempId}_r${rowIndex + 1}`] === column;
                      return (
                        <td key={colIndex}>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`${question.tempId}_r${rowIndex + 1}`}
                              value={`${column}`}
                              checked={checked}
                              onChange={handleMatrixChange}
                              required={question.isMandatory}
                            />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>

            </table>
          </div>

        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default MatrixQuestion;
