import { useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

function SliderScaleQuestion({ question, handleChange,formValues, showError}) {
  const [value, setValue] = useState(question.minValue);
  const hasAnswer = formValues[question.tempId];

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);  
    handleChange({
      target: { name: question.tempId, value: newValue },
    });
  };

  const handleDecreaseClick = () => {
    const newValue = value - question.step;
    if (newValue >= question.minValue) {
      setValue(newValue);
      handleChange({
        target: { name: question.tempId, value: newValue },
      });
    }
  };

  const handleIncreaseClick = () => {
    const newValue = value + question.step;
    if (newValue <= question.maxValue) {
      setValue(newValue);
      handleChange({
        target: { name: question.tempId, value: newValue },
      });
    }
  };

  const isMandatory = question.isMandatory;

  const sliderStyle = {
    width: "100%",
    background:
      "linear-gradient(to right, #87CEFA, #87CEFA " +
      ((value - question.minValue) / (question.maxValue - question.minValue)) * 100 +
      "%, #f5f5f5 " +
      ((value - question.minValue) / (question.maxValue - question.minValue)) * 100 +
      "%, #f5f5f5)",
  };

  return (
    <Card className={`mt-3 ${isMandatory && showError  && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group controlId={question.tempId}>
          <div className="d-flex flex-column">

            <Form.Label>
              <b>
                {question.questionText}
                {isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />

            )}
          </div>
          <Row className="align-items-center">
            <Col xs={3} sm={1}>
              <Button variant="outline-secondary" onClick={handleDecreaseClick}>
                -
              </Button>
            </Col>
            <Col className="justify-content-center mb-3 align-items-center" xs={6} sm={10}>
              <Row className="text-center">
                <div className="mx-2">{value}</div>
                <input
                  type="range"
                  name={question.tempId}
                  id={question.tempId}
                  min={question.minValue}
                  max={question.maxValue}
                  step={question.step}
                  value={value}
                  onChange={handleInputChange}
                  className="form-control-range"
                  style={sliderStyle}
                  isInvalid={isMandatory && !value}
                />
              </Row>
            </Col>

            <Col xs={3} sm={1}>
              <Button variant="outline-secondary" onClick={handleIncreaseClick}>
                +
              </Button>
            </Col>
            <Col xs={12} sm={12}>

            </Col>
          </Row>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default SliderScaleQuestion;
