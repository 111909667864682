import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

function BooleanQuestionCard({ question, handleChange, formValues, showError }) {
  const hasAnswer = formValues[question.tempId];
  const isMandatory = question.isMandatory;
  const [checked, setChecked] = useState(Boolean(question.value));

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setChecked(checked);
    handleChange({ target: { name, value: checked } });
  };

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group className="mb-3">
          <div className="d-flex flex-column">
            <Form.Label>
              <b>
                {question.questionText}
                {isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
              <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
            </Form.Label>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>
          <div
            className="d-flex justify-content-start align-items-start"
          >
            <Form.Label className="me-2">{question.labelFalse || "No"}</Form.Label>
            <div
            // className="form-check form-switch"
            >
              <Form.Check
                // className="form-check-input"
                type="switch"
                id={`${question.tempId}-switch`}
                name={question.tempId}
                onChange={handleSwitchChange}
                checked={checked}
                style={{ transform: "scale(1.5)", marginLeft: "10px", marginRight: "5px" }}
              />
            </div>
            <Form.Label className="ms-2">{question.labelTrue || "Yes"}</Form.Label>
          </div>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default BooleanQuestionCard;
