import { useState } from "react";
import { Card } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import "./image.css";

function ImagePicker({ question, handleChange, formValues, showError }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const hasAnswer = formValues[question.tempId];
  const isMandatory = question.isMandatory;

  const handleSelectImage = (choice) => {
    setSelectedImage(choice);
    handleChange({
      target: { name: question.tempId, value: choice.value },
    });
  };

  const isImageSelected = (choice) => {
    return selectedImage && selectedImage.value === choice.value;
  };


  const sortChoices = (choices, order) => {
    if (order === "random") {
      return choices.sort(() => Math.random() - 0.5);
    } else if (order === "ascending") {
      return choices.sort((a, b) => a.value.localeCompare(b.value));
    } else if (order === "descending") {
      return choices.sort((a, b) => b.value.localeCompare(a.value));
    } else {
      return choices;
    }
  };

  const sortedChoices = sortChoices(question.choices, question.choicesOrder);

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}
      style={{ width: "100%" }}>
      <Card.Body>
        <div className="d-flex flex-column">

          <b>
            <label htmlFor={question.tempId}>
              {question.questionText}{" "}
              {isMandatory && <span className="text-danger">*</span>}
            </label>
          </b>
          <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
          {isMandatory && showError && !hasAnswer && (
            <ErrorComponent message={question.requiredErrorText} />
          )}
        </div>
        <div className="d-flex flex-wrap mt-3">
          {sortedChoices.map((choice) => (
            <div
              key={choice.value}
              className={`image-container ${isImageSelected(choice) ? "selected" : ""
                }`}
            >
              <img
                src={choice.imageLink}
                alt={choice.value}
                className="image"
                style={{ width: "200px", height: "200px" }}
                onClick={() => handleSelectImage(choice)}
              />
              {isImageSelected(choice) && (
                <div className="selected-overlay right">
                  <i className="fas fa-check"></i>
                </div>
              )}
            </div>
          ))}
        </div>


      </Card.Body>
    </Card>
  );
}

export default ImagePicker;
