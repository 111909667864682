import React from "react";
import { Form, Card } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";

const MobileMultiMatrixQuestion = ({
    question,
    formValues,
    handleCheckboxChange,
    showError
}) => {
    // const hasAnswer = Object.keys(formValues).some((key) => key.startsWith(`${question.tempId}_r`) && formValues[key].some((value) => value !== ""));
    const hasAnswer = formValues[question.tempId]?.some((answer) => {
        return Object.values(answer).some((rowValues) => {
            return rowValues.some((value) => value !== "");
        });
    });
    return (
        <Card
            className={`mt-3 ${question.isMandatory && showError && !hasAnswer
                ? "border-danger"
                : ""
                }`}
        >
            <Card.Body>
                <Form.Group>
                    <div className="d-flex flex-column">
                        <Form.Label>
                            <b>
                                {question.questionText}
                                {question.isMandatory && (
                                    <span className="text-danger">*</span>
                                )}
                            </b>
                        </Form.Label>
                        <Form.Label>
                            <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
                        </Form.Label>
                        {question.isMandatory && showError && !hasAnswer && (
                            <ErrorComponent
                                message={question.requiredErrorText}
                            />
                        )}
                    </div>
                    <div className="row">
                        {question.rows.map((row, rowIndex) => (
                            <div key={rowIndex} className="col-12 col-md-6">
                                <div className="border p-3 mb-3">
                                    <h5>{row}</h5>
                                    {question.columns.map((column, colIndex) => (
                                        <Form.Check
                                            key={colIndex}
                                            type="checkbox"
                                            id={`${question.tempId}_r${rowIndex}_${colIndex}`}
                                            tempId={`${question.tempId}_r${rowIndex}_${colIndex}`}
                                            value={column}
                                            label={column}
                                            checked={formValues[`${question.tempId}_r${rowIndex}`]?.includes(
                                                column
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(
                                                    e,
                                                    rowIndex,
                                                    colIndex
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default MobileMultiMatrixQuestion;
