import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import './dateselection.css'
function DateQuestion({ question, handleChange, formValues, showError }) {
  const [valueAnswer, setValueAnswer] = useState("");
  const hasAnswer = formValues[question.tempId];
  const isMandatory = question.isMandatory;

  const handleInputChange = (event) => {
    setValueAnswer(event.target.value);
    handleChange({
      target: { name: question.tempId, value: event.target.value },
    });
  };


  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group controlId={question.tempId}>
          <div className="d-flex flex-column">

            <Form.Label>
              <b>
                {question.questionText}
                {isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
              <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
            </Form.Label>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />

            )}
          </div>
          <Form.Control
            type="date"
            name={question.tempId}
            id={question.tempId}
            value={valueAnswer}
            onChange={handleInputChange}
            className="form-control date-input"
          // isInvalid={isMandatory && showError && !valueAnswer}
          // style={{ width: "20%", }}
          />

        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default DateQuestion;
