import React, { useState, useEffect } from "react";
import HomeComponent from "../Questionnaire/HomeComponent/HomeComponent";

function LocationAndDate({ word,wholeWord }) {
    const [location, setLocation] = useState({ lat: null, lng: null });
    // const [location, setLocation] = useState({ lat: 37.7749, lng: -122.4194 });


    useEffect(() => {
        if (!navigator.geolocation) {
            console.error("Geolocation is not supported by your browser");
            return;
        }
        // get location
        const success = (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
        };

        const error = (error) => {
            console.error(`Error getting location: ${error.message}`);
        };

        navigator.geolocation.getCurrentPosition(success, error);
       
    }, []);

    return (
        <div>
            {word != null ? (
                // <HomeAppComponent
                //     word={word}
                //     Latitude={location.lat}
                //     Longitude={location.lng}
                //     wholeWord={wholeWord}

                // />
                <HomeComponent
                    word={word}
                    Latitude={location.lat}
                    Longitude={location.lng}
                    wholeWord={wholeWord}

                />
                ) : (
                <div>Loading...</div>
            )}

        </div>
    );
}

export default LocationAndDate;
