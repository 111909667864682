import React, { useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import "./RatingQuestion.css";

function RatingQuestion({ question, handleChange, formValues, showError }) {
  const [selectedRating, setSelectedRating] = useState(null);
  const hasAnswer = formValues[question.tempId];
  const isMandatory = question.isMandatory;

  const handleRatingClick = (event) => {
    const rating = parseInt(event.target.value);
    setSelectedRating(rating);
    handleChange({ target: { name: question.tempId, value: rating } });
  };

  const smileyList = ["😞", "😕", "😐", "😊", "😄", "😃", "🤩"];

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">
            <Form.Label>
              <b>
                {question.questionText}
                {isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
              <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
            </Form.Label>
            {isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>
          <Row>
            {[...Array(question.rateMax)].map((_, index) => (
              <Col xs={2} md={1} key={index}>
                <div
                  className={`rating-container ${selectedRating && selectedRating === index + 1 ? "active" : ""}`}
                >
                  <button
                    className="rating-button"
                    type="button"
                    name={question.tempId}
                    value={index + 1}
                    onClick={handleRatingClick}
                    required={question.isMandatory}
                  >
                    {smileyList[index]}
                  </button>
                </div>
              </Col>
            ))}
          </Row>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default RatingQuestion;

