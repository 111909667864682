import React, { useState, useEffect } from "react";
import axios from 'axios';
import { InValidURL } from "../../Components/InValidURL";
import { API_END_POINT } from "../../config";
import Questionnaire from "../Questionnaire/Questionnaire";
import { Container, Row, Col } from "react-bootstrap";

export default function HomeComponent({ word, Latitude, Longitude, wholeWord }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [errLoading, setErrLoading] = useState(false);
  const InvalidURLMsg = "There is no data with the mentioned URL. Please provide a valid one.";

  // dummy survey code
  const surveyLoad = {
    "header": {
      "title": "Survey Designer",
      "description": "This is the desciption which needs to be tested and verified from all the perspectives.",
      "logoPosition": "right",
      "titlePosition": "center",
      "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg4POvLfTXehFY2l6A-BRMYSsUq0EnnPrvCzMv9NO0&s",
      "surveyheadingForeground": {
        "margin": "auto",
        "color": "#ffffff",
        "font-size": "27px"
      },
      "surveyheadingBackground": {
        "background-color": "#FF0000",
        "padding": "10px"
      }
    },
    "footer": {
      "previousbuttonlabel": "<<",
      "previousbuttonstyle": {
        "background-color": "#0000FF"
      },
      "nextbuttonlabel": ">>",
      "nextbuttonstyle": {
        "background-color": "#0000FF"
      },
      "progressbar": true,
      "progressbarstyle": {
        "background-color": "#FFFFFF"
      },
      "footerBackgroundcolor": "#000000"
    },
    "pages": [
      {
        "elements": [
          {
            "id": -1,
            "tempId": "Q1-1",
            "type": "numeric",
            "typeId": 5,
            "questionText": "What is your Age?",
            "questionDescription": "To know your Age,.",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 5,
              "name": "",
              "label": "Numeric",
              "options": "",
              "questionType": "numeric",
              "icon": "fa fa-calculator fa-fw"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 1
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 2
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": true,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-2",
            "type": "NPS",
            "typeId": 6,
            "questionText": "How much do you rate this Airtel Subscription out of 10 ?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 6,
              "name": "",
              "label": "NPS",
              "options": "",
              "questionType": "NPS",
              "icon": "fa fa-tachometer fa-fw"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 3
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 4
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": true,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "({Q1-1} = 25)",
            "visibleIf": "({Q1-1} = 25)",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-3",
            "type": "selectList",
            "typeId": 3,
            "questionText": "What is your location?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 3,
              "name": "",
              "label": "Select List",
              "options": "",
              "questionType": "selectList",
              "icon": "fa fa-caret-square-o-down fa-fw"
            },
            "choices": [
              "Hyderabad,Bangalore,Mumbai,Kolkata,Delhi,Chennai,Kashmir,Ahemedabad,Dharmasala"
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "({Q1-1} = 25 and  {Q1-2} = 8)",
            "visibleIf": "({Q1-1} = 25 and  {Q1-2} = 8)",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-4",
            "type": "date",
            "typeId": 7,
            "questionText": "When did your subscription starts ?",
            "questionDescription": "Provide us the date of your renewal",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 7,
              "name": "",
              "label": "Date",
              "options": "",
              "questionType": "date",
              "icon": "fa fa-calendar fa-fw"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 5
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 6
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-5",
            "type": "radiogroup",
            "typeId": 1,
            "questionText": "This is a Single Choice Question\nWhere do you want to settle for next 5 years?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 1,
              "name": "",
              "label": "Single Choice",
              "options": "",
              "questionType": "radiogroup",
              "icon": "fa fa-dot-circle-o fa-fw"
            },
            "choices": [
              {
                "value": "Bangalore",
                "text": "Bangalore",
                "tempId": 3
              },
              {
                "value": "Chennai",
                "text": "Chennai",
                "tempId": 4
              },
              {
                "value": "Hyderabad",
                "text": "Hyderabad",
                "tempId": 5
              },
              {
                "value": "Delhi",
                "text": "Delhi",
                "tempId": 6
              },
              {
                "id": "",
                "text": "Other",
                "value": "Other",
                "tempId": 7,
                "optionType": "OTHER"
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": true,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "random",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-6",
            "type": "checkbox",
            "typeId": 2,
            "questionText": "Select your mobile networks?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 2,
              "name": "",
              "label": "Multi Choice",
              "options": "",
              "questionType": "checkbox",
              "icon": "fa fa-check-square-o fa-fw"
            },
            "choices": [
              {
                "value": "Jio",
                "text": "Jio",
                "tempId": 9
              },
              {
                "value": "Airtel",
                "text": "Airtel",
                "tempId": 10
              },
              {
                "value": "Docomo",
                "text": "Docomo",
                "tempId": 11
              },
              {
                "value": "Vodafone",
                "text": "Vodafone",
                "tempId": 12
              },
              {
                "value": "Idea",
                "text": "Idea",
                "tempId": 13
              },
              {
                "id": "",
                "text": "Other",
                "value": "Other",
                "tempId": 1,
                "optionType": "OTHER"
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": true,
            "yesOrNo": false,
            "choicesOrder": "descending",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-7",
            "type": "matrix",
            "typeId": 15,
            "questionText": "Matrix Question",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 15,
              "name": "",
              "label": "Matrix Single Choice",
              "options": "",
              "questionType": "matrix",
              "icon": "fa fa-list"
            },
            "rows": [
              "Row 1",
              "Row2"
            ],
            "columns": [
              "Col1",
              "Col2",
              "Col3"
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "({Q1-6} = [Airtel,Jio])",
            "visibleIf": "({Q1-6} = [Airtel,Jio])",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-8",
            "type": "multiselectdropdown",
            "typeId": 12,
            "questionText": "multi Selection",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 12,
              "name": "",
              "label": "Multi Selection Dropdown",
              "options": "",
              "questionType": "multiselectdropdown",
              "icon": "fa fa-light fa-list-alt"
            },
            "choices": [
              {
                "id": "",
                "text": "AA",
                "value": "AA",
                "tempId": 14
              },
              {
                "id": "",
                "text": "BB",
                "value": "BB",
                "tempId": 15
              },
              {
                "id": "",
                "text": "CC",
                "value": "CC",
                "tempId": 16
              },
              {
                "id": "",
                "text": "DD",
                "value": "DD",
                "tempId": 17
              },
              {
                "id": "",
                "text": "EE",
                "value": "EE",
                "tempId": 18
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q1-9",
            "type": "matrixmultiChoice",
            "typeId": 16,
            "questionText": "Matrix Multi CHoice",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 16,
              "name": "",
              "label": "Matrix Multi Choice",
              "options": "",
              "questionType": "matrixmultiChoice",
              "icon": "fa fa-th-large"
            },
            "rows": [
              "Row1",
              "Row2",
              "Row 3"
            ],
            "columns": [
              "Col1",
              "Col2"
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          }
        ]
      },
      {
        "elements": [
          {
            "id": -1,
            "tempId": "Q2-5",
            "type": "starRating",
            "typeId": 8,
            "questionText": "What rating can you give us for this subscription?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 8,
              "name": "",
              "label": "Rating",
              "options": "",
              "questionType": "starRating",
              "icon": "fa fa-star fa-fw"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 7
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 8
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": true,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q2-2",
            "type": "rating",
            "typeId": 9,
            "questionText": "How much happy are you with this?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 9,
              "name": "",
              "label": "Emoji",
              "options": "",
              "questionType": "rating",
              "icon": "fa fa-smile-o fa-fw"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 9
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 10
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": true,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "({Q1-5} = [Hyderabad,Bangalore])",
            "visibleIf": "({Q1-5} = [Hyderabad,Bangalore])",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q2-3",
            "type": "ranking",
            "typeId": 10,
            "questionText": "Rank these Orders based on your Choice?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 10,
              "name": "",
              "label": "Ranking",
              "options": "",
              "questionType": "ranking",
              "icon": "fa fa-trophy"
            },
            "choices": [
              "Jio,Docomo,BSNL,Aircel,Vodafone,Idea"
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          }
        ]
      },
      {
        "elements": [
          {
            "id": -1,
            "tempId": "Q3-4",
            "type": "singleDropdown",
            "typeId": 11,
            "questionText": "Other than Airtel which one do you prefer more?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 11,
              "name": "",
              "label": "Single Selection",
              "options": "",
              "questionType": "singleDropdown",
              "icon": "fa fa-caret-down fa-fw"
            },
            "choices": [
              {
                "value": "Jio",
                "text": "Jio",
                "tempId": 19
              },
              {
                "value": "Docomo",
                "text": "Docomo",
                "tempId": 20
              },
              {
                "value": "BSNL",
                "text": "BSNL",
                "tempId": 21
              },
              {
                "value": "Aircel",
                "text": "Aircel",
                "tempId": 22
              },
              {
                "value": "Vodafone",
                "text": "Vodafone",
                "tempId": 23
              },
              {
                "value": "Idea",
                "text": "Idea",
                "tempId": 24
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          },
          {
            "id": -1,
            "tempId": "Q3-2",
            "type": "boolean",
            "typeId": 13,
            "questionText": "Would you like to continue this subscription?",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 13,
              "name": "",
              "label": "Yes or No",
              "options": "",
              "questionType": "boolean",
              "icon": "fa fa-thumbs-up fa-fw"
            },
            "labelTrue": "OfCouse yes!!",
            "labelFalse": "Definitely Not!!",
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          }
        ]
      },
      {
        "elements": [
          {
            "id": -1,
            "tempId": "Q4-3",
            "type": "sliderScale",
            "typeId": 14,
            "questionText": "Slider Question just to verify this",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 0,
            "maxValue": 100,
            "selectedType": {
              "id": 14,
              "name": "",
              "label": "Slider Scale",
              "options": "",
              "questionType": "sliderScale",
              "icon": "fa fa-sliders"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 25
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 26
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": true,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true,
            "step": 5
          },
          {
            "id": -1,
            "tempId": "Q4-2",
            "type": "openText",
            "typeId": 4,
            "questionText": "Provide us some Feedback!!",
            "questionDescription": "",
            "rateMax": 5,
            "minValue": 18,
            "maxValue": 99,
            "selectedType": {
              "id": 4,
              "name": "",
              "label": "Open Text",
              "options": "",
              "questionType": "openText",
              "icon": "fa fa-square-o fa-fw"
            },
            "choices": [
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 27
              },
              {
                "id": "",
                "text": "",
                "value": "",
                "tempId": 28
              }
            ],
            "dateFormat": "dd/MM/yyyy",
            "isMandatory": false,
            "optionWithImage": false,
            "others": false,
            "yesOrNo": false,
            "choicesOrder": "normal",
            "codedOption": false,
            "requiredErrorText": "Answer cannot be empty",
            "DLFormulaText": "",
            "visibleIf": "",
            "placeHolderText": "Type Here..",
            "status": "ADDED",
            "errorStatus": true
          }
        ]
      }
    ]
  };

  useEffect(() => {
    if (word !== null && word !== "") {
      setLoading(true);

      axios.get(`${API_END_POINT.BASE_URL}/api/survey/GetSurveyDefinition/${word}`)
        .then(response => {
          let surveyData = response.data.data;
          setData(surveyData);
          // setData(surveyLoad);
          setLoading(false);
        })
        .catch(ex => {
          setErrLoading(true);
        });
    }
  }, [word]);

  return (
    <div className="App">
      {
        <>
          {!loading && Object.keys(data).length > 0 ? (
            <Questionnaire
              pages={data}
              Latitude={Latitude}
              Longitude={Longitude}
              wholeWord={wholeWord}
            />
          ) : (
            <InValidURL msg={InvalidURLMsg} errLoading={errLoading} />
          )}
        </>
      }
    </div>
  );
}
