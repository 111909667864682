import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import "./RadioQuestion.css";

function RadioQuestion({ question, handleChange, formValues, showError }) {
  const hasAnswer = formValues[question.tempId];
  const [sortedChoices, setSortedChoices] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   let choicesToRender = question.choices;
  //   if (question.choicesOrder === "random") {
  //     choicesToRender = question.choices.sort(() => Math.random() - 0.5);
  //   } else if (question.choicesOrder === "ascending") {
  //     choicesToRender = question.choices.sort();
  //   } else if (question.choicesOrder === "descending") {
  //     choicesToRender = question.choices.sort().reverse();
  //   }
  //   setSortedChoices(choicesToRender);
  // }, [question]);

  useEffect(() => {
    let choicesToRender = question.choices;

    // Filter out the "Other" option from the choices
    const otherOption = choicesToRender.find((choice) => choice.value === "Other");
    choicesToRender = choicesToRender.filter((choice) => choice.value !== "Other");

    if (question.choicesOrder === "random") {
      choicesToRender = choicesToRender.sort(() => Math.random() - 0.5);
    } else if (question.choicesOrder === "ascending") {
      choicesToRender = choicesToRender.sort();
    } else if (question.choicesOrder === "descending") {
      choicesToRender = choicesToRender.sort().reverse();
    }

    // Add the "Other" option at the end of the sorted choices, if it exists
    if (otherOption) {
      choicesToRender.push(otherOption);
    }

    setSortedChoices(choicesToRender);
  }, [question]);


  const getNumCols = () => {
    if (!isMobile) {
      // Desktop
      return question.webOptions || 1;
    } else {
      // Mobile
      return question.mobileOptions || 1;
    }
  };

  const handleOptionsChange = (event) => {
    const selectedValue = event.target.value;
    handleChange(event);
    if (selectedValue === "Other") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
  };

  const handleOtherInputChange = (event) => {
    const inputValue = event.target.value;
    setOtherInputValue(inputValue);
    const otherWithValue = `Other: ${inputValue}`;
    handleChange({
      target: {
        name: question.tempId,
        value: otherWithValue,
      },
    });
  };


  return (
    <Card className={`mt-3 ${question.isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">
            <Form.Label>
              <b>
                {question.questionText}
                {question.isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            {question.isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}
          </div>
          <Row className="radio-row">
            {sortedChoices.map((choice, index) => (
              <Col xs={12 / getNumCols()} key={index}>
                <div className="radio-box" onClick={() => {
                  const radio = document.querySelector(`input[name=${question.tempId}][value='${choice.value}']`);
                  radio.click();
                }}>
                  <Form.Check
                    type="radio"
                    label={choice.text}
                    name={question.tempId}
                    value={choice.value}
                    onChange={handleOptionsChange}
                    required={question.isMandatory}
                  />
                </div>
              </Col>
            ))}
          </Row>
          {showOtherInput && (
            <Row className="mt-3">
              <Col xs={12}>
                <div className="other-input">
                  <Form.Control
                    type="text"
                    placeholder="Type here..."
                    value={otherInputValue}
                    onChange={handleOtherInputChange}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default RadioQuestion;
