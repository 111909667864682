import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import ErrorComponent from "../../../Components/ErrorMessage";
import "./MultiChoiceQuestion.css";

function MultiChoiceQuestion({ question, handleChange, formValues, showError }) {
  const [sortedChoices, setSortedChoices] = useState([]);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isAnswerValidM, setIsAnswerValidM] = useState(true);
  const [otherText, setOtherText] = useState("");
  const hasAnswer = formValues[question.tempId];
  const otherInputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let choicesToRender = question.choices;

    // Filter out the "Other" option from the choices
    const otherOption = choicesToRender.find((choice) => choice.value === "Other");
    choicesToRender = choicesToRender.filter((choice) => choice.value !== "Other");

    if (question.choicesOrder === "random") {
      choicesToRender = shuffleArray(choicesToRender);
    } else if (question.choicesOrder === "ascending") {
      choicesToRender = choicesToRender.sort((a, b) => a.text.localeCompare(b.text));
    } else if (question.choicesOrder === "descending") {
      choicesToRender = choicesToRender.sort((a, b) => b.text.localeCompare(a.text));
    }

    // Add the "Other" option at the end of the sorted choices, if it exists
    if (otherOption) {
      choicesToRender.push(otherOption);
    }

    setSortedChoices(choicesToRender);
  }, [question]);

  const getNumCols = () => {
    if (!isMobile) {
      // Desktop
      return question.webOptions || 1;
    } else {
      // Mobile
      return question.mobileOptions || 1;
    }
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const handleCheckboxClick = (index) => {
    const checkbox = document.getElementsByName(question.tempId)[index];
    checkbox.checked = !checkbox.checked;
    handleChange({ target: checkbox, otherText:otherText }, question.type);

    setSelectedChoices((prevChoices) => {
      const newChoices = [...prevChoices];
      const value = checkbox.value;

      if (checkbox.checked) {
        newChoices.push(value);
        if (value === "Other") {
          focusOtherInput();
        }
      } else {
        const index = newChoices.indexOf(value);
        if (index > -1) {
          newChoices.splice(index, 1);
        }
      }

      return newChoices;
    });
  };

  const handleOtherTextChange = (e) => {
    const inputText = e.target.value;
    setOtherText(inputText);
    const otherValue = `Other: ${inputText}`;
    handleChange({
      target: { value: otherValue, name: question.tempId, type: question.type, otherText:inputText },
    });
  };


  const focusOtherInput = () => {
    if (otherInputRef.current) {
      otherInputRef.current.focus();
    }
  };

  const handleValidation = () => {
    if (question.maxSelect && selectedChoices.length > parseInt(question.maxSelect)) {
      return question.maxSelectErrorText;
    }
    if (question.minSelect && selectedChoices.length < parseInt(question.minSelect)) {
      return question.minSelectErrorText;
    }
    return null;
  };

  return (
    <Card className={`mt-3 ${question.isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form.Group>
          <div className="d-flex flex-column">
            <Form.Label>
              <b>
                {question.questionText}
                {question.isMandatory && <span className="text-danger">*</span>}
              </b>
            </Form.Label>
            <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
            {question.isMandatory && showError && !hasAnswer && (
              <ErrorComponent message={question.requiredErrorText} />
            )}

            {handleValidation() && showError && (
              <ErrorComponent message={handleValidation()} />
            )}
          </div>
          <Row style={{ padding: '10px' }}>
            {sortedChoices.map((choice, index) => (
              <Col
                xs={12 / getNumCols()}
                key={index}
                className="checkbox-container"
                onClick={() => handleCheckboxClick(index)}
              >
                <Form.Check
                  type="checkbox"
                  label={choice.text}
                  name={question.tempId}
                  value={choice.value}
                  onChange={(e) => {
                    e.target.checked = !e.target.checked;
                    handleChange(e, question.type);
                  }}
                  required={question.isMandatory}
                />
              </Col>
            ))}
          </Row>
          {selectedChoices.includes("Other") && (
            <Row style={{ padding: '10px' }}>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter other text"
                  value={otherText}
                  onChange={handleOtherTextChange}
                  ref={otherInputRef}
                />
              </Col>
            </Row>
          )}
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

export default MultiChoiceQuestion;
