import { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import ErrorComponent from '../../../Components/ErrorMessage';

const NPSQuestion = ({ question, handleChange, formValues, showError }) => {
  const hasAnswer = formValues[question.tempId];
  const isMandatory = question.isMandatory;

  const [selectedNPSValue, setSelectedNPSValue] = useState(null);

  const npsData = [
    { value: '0', className: ' btn-danger margin-right', text: 'Not at all likely' },
    { value: '1', className: ' btn-danger margin-right', text: '' },
    { value: '2', className: ' btn-danger margin-right', text: '' },
    { value: '3', className: ' btn-danger margin-right', text: '' },
    { value: '4', className: ' btn-danger margin-right', text: '' },
    { value: '5', className: ' btn-danger margin-right', text: '' },
    { value: '6', className: ' btn-danger margin-right', text: '' },
    { value: '7', className: ' btn-warning margin-right', text: '' },
    { value: '8', className: ' btn-warning margin-right', text: '' },
    { value: '9', className: ' btn-success margin-right', text: '' },
    { value: '10', className: ' btn-success margin-right', text: 'Extremely likely' }
  ];

  return (
    <Card className={`mt-3 ${isMandatory && showError && !hasAnswer ? "border-danger" : ""}`}>
      <Card.Body>
        <Form>
          <Form.Group controlId={question.tempId}>
            <div className="d-flex flex-column">
              <Form.Label>
                <b>
                  {question.questionText}
                  {isMandatory && <span className="text-danger">*</span>}
                </b>
              </Form.Label>
              <Form.Label>
                <b style={{ color: 'lightblue' }}>{question.questionDescription}</b>
              </Form.Label>
              {isMandatory && showError && !hasAnswer && (
                <ErrorComponent message={question.requiredErrorText} />
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <p style={{ 'fontWeight': 'bold', width: '30%', textAlign: 'left' }}>Not at all likely</p>
              <p style={{ width: '50%' }}></p>
              <p style={{ 'fontWeight': 'bold', width: '30%', textAlign: 'right' }}>Extremely likely</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {npsData.map(({ value, className, text, textMargin }, index) => (
                <div className="d-flex flex-column align-items-center" style={{ width: `${100 / npsData.length}%` }}>
                  <Button
                    key={value}
                    style={{
                      fontSize: '0.7em',
                      padding: '10px',
                      margin: '2px',
                    }}
                    className={`btn ${className} ${selectedNPSValue === value ? 'selected selected-light-blue' : ''
                      }`}
                    onClick={() => {
                      setSelectedNPSValue(value);
                      handleChange(question.tempId, value);
                    }}
                  >
                    {value}
                  </Button>

                  {/* <p style={{ margin: '0', ...textMargin }}>{text}</p> */}
                </div>
              ))}
            </div>

          </Form.Group>
        </Form>
      </Card.Body>
    </Card>

  );
};

export default NPSQuestion;
