import React from "react";
import { Card,Form } from "react-bootstrap";

const HeadingQuestion = ({ questionText }) => {
    return (
        <Card className="my-3">
            <Card.Body>
                <Form.Group>
                    <Form.Label>
                        <b>
                            {questionText}
                        </b></Form.Label>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default HeadingQuestion;
